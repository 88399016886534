import '@/app/commons';
import Vue from 'vue';
import { onMounted } from '@vue/composition-api';
// import { Debug } from '@/shared/utils/helpers';
import vuetify from '../plugins/vuetify';
import Header from '@/app/layouts/header';
import Footer from '@/app/layouts/footer';
import { data } from '@/app/connectors/observable';
import Carga from '@/app/layouts/carga';

const carga = Carga;

const header = Header;
const footer = Footer;
const Debug = console;
const Index = Vue.extend({
  setup() {
    onMounted(() => {
      Debug.log('Index page run');
      data.listo = true;
    });
    return {};
  },
});
export default new Index({
  el: '#index',
  vuetify,
  delimiters: ['[{', '}]'],
});
